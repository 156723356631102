<template>
    <div>
        <header>
            <video-background
                :src="require(`@/components/assets/walking.mp4`)"
                style="height: 100vh;"
                id="videoBackground"
                :poster="require(`@/components/assets/lake.jpg`)"
                :sources="[
                    {
                        src: require(`@/components/assets/walking-mobile.mp4`),
                        res: 638,
                        autoplay: true,
                        poster: require(`@/components/assets/lake.jpg`)
                    }
                ]"
            >
                <div class="header-overlay"></div>
                <div class="header-content">
                    <h1 v-html="headerText.title" />
                    <p v-html="bannerText.titleText" />
                </div>
            </video-background>
        </header>
        <section class="section section-light">
            <h2 v-html="headerText.aboutUs" />
            <p v-html="bannerText.aboutUsText" />
            <router-link to="/contactUs" class="btn-light"
                >About Us</router-link
            >
        </section>
        <div class="pimg3">
            <div class="ptext">
                <span class="border trans" v-html="headerText.safeAtHome" />
            </div>
        </div>
        <section class="section section-dark">
            <h2 v-html="headerText.safeAtHome" />
            <section class="boxes">
                <div class="box">
                    <h2 v-html="bannerText.safeAtHomeOne.title" />
                    <p v-html="bannerText.safeAtHomeOne.description" />
                </div>
                <div class="box">
                    <h2 v-html="bannerText.safeAtHomeTwo.title" />
                    <p v-html="bannerText.safeAtHomeTwo.description" />
                </div>
            </section>
            <router-link to="/safeAtHome" class="btn-dark"
                >Learn More</router-link
            >
        </section>
        <div class="mobile ? pimg8 : pimg2">
            <div v-if="mobile" class="ptext">
                <span class="border trans" v-html="headerText.shine" />
            </div>
        </div>
        <section class="section section-light">
            <h2 v-html="headerText.shine" />
            <p v-html="bannerText.shine" />
            <router-link to="/facebook" class="btn-light"
                >Show Your Support</router-link
            >
        </section>
        <div class="pimg9">
            <div class="ptext">
                <span class="border trans" v-html="headerText.michener" />
            </div>
        </div>
        <section class="section section-dark">
            <!-- <h2 v-html="headerText.michener" /> -->
            <p v-html="bannerText.michener" />
        </section>

        <div class="pimg1">
            <div class="ptext">
                <span class="border">
                    {{ footerText.footer }}
                </span>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { headerText, bannerText, footerText } from '../components/constants';
import Footer from './Footer';
export default {
    name: 'NorthHarbor',
    components: {
        Footer
    },
    metaInfo: {
        title: 'North Harbor',
        meta: [
            {
                vmid:
                    'At North Harbor we are dedicated to assisting and advocating for you an your loved ones.',
                name:
                    'At North Harbor we are dedicated to assisting and advocating for you an your loved ones.',
                content: 'My North Harbor'
            }
        ]
    },
    data() {
        return {
            headerText,
            bannerText,
            footerText,
            mobile: window.innerWidth > 568 ? false : true
        };
    },
    mounted() {
        window.scrollTo(0, 0);
    }
};
</script>

<style>
@import '../components/styles/style.css';
@import 'https://fonts.googleapis.com/css?family=Tangerine:700';
</style>
