<template>
    <div>
        <i class="fa fa-angle-left" :class="iconSize" @click="goHome"></i>
        <i class="fa fa-home" :class="iconSize" @click="goHome"></i>
        <div
            class="sticky-header-content"
            v-bind:style="{ position: 'absolute', top: headerHight }"
            ref="sticky-header-content"
        >
            <h1>{{ headerText.aboutUs }}</h1>
        </div>
        <div class="side-page-v-header pimg4"></div>
        <div v-if="mobile">
            <section class="boxes">
                <div class="box text">
                    <h2 v-html="profiles.lynda.name" />
                    <p v-html="profiles.lynda.description" />
                </div>
                <div class="box">
                    <img src="../components/assets/lynda-hepker.jpg" />
                </div>
            </section>
            <section class="boxes">
                <div class="box text">
                    <h2 v-html="profiles.shari.name" />
                    <p v-html="profiles.shari.description" />
                </div>
                <div class="box">
                    <img src="../components/assets/shari-1.jpg" />
                </div>
            </section>
            <section class="boxes">
                <div class="box text">
                    <h2 v-html="profiles.jeremy.name" />
                    <p v-html="profiles.jeremy.description" />
                </div>
                <div class="box">
                    <img src="../components/assets/jeremy.jpg" />
                </div>
            </section>
        </div>
        <div v-else>
            <section class="desktopProfiles">
                <div class="lyndaText text">
                    <h2 v-html="profiles.lynda.name" />
                    <p v-html="profiles.lynda.description" />
                </div>
                <div class="lyndaImg">
                    <img src="../components/assets/lynda-hepker.jpg" />
                </div>
                <div class="sText text">
                    <h2 v-html="profiles.shari.name" />
                    <p v-html="profiles.shari.description" />
                </div>
                <div class="sImg">
                    <img src="../components/assets/shari-1.jpg" />
                </div>
                <div class="jeremyText text">
                    <h2 v-html="profiles.jeremy.name" />
                    <p v-html="profiles.jeremy.description" />
                </div>
                <div class="jeremyImg">
                    <img src="../components/assets/jeremy.jpg" />
                </div>
            </section>
        </div>
        <div class="divider" id="contactUs">Contact Us</div>
        <section class="boxes">
            <div class="box text">
                <h2 v-html="bannerText.contactUs.title" />
                <p v-html="bannerText.contactUs.description" />
            </div>
            <div class="box">
                <ContactForm></ContactForm>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script>
import {
    headerText,
    bannerText,
    footerText,
    profiles
} from '../components/constants';
import ContactForm from './ContactForm';
import Footer from './Footer';
export default {
    name: 'SafeAtHome',
    components: {
        ContactForm,
        Footer
    },
    data() {
        return {
            headerText,
            bannerText,
            footerText,
            profiles,
            iconSize: window.innerWidth > 568 ? 'w3-jumbo' : 'w3-xxlarge',
            headerHight: window.innerWidth > 568 ? '20%' : '25%',
            mobile: window.innerWidth > 568 ? false : true
        };
    },
    mounted() {
        window.scrollTo(0, 0);
        this.handleScroll();
    },
    created() {
        window.addEventListener('resize', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleScroll);
    },
    methods: {
        goHome() {
            this.$router.push('/');
        },
        handleScroll() {
            if (document.querySelector('.sticky-header-content')) {
                const stickyElem = document.querySelector(
                    '.sticky-header-content'
                );

                var currStickyPos =
                    stickyElem.getBoundingClientRect().top +
                    window.pageYOffset -
                    25;
                window.onscroll = function() {
                    if (window.pageYOffset >= currStickyPos) {
                        stickyElem.style.position = 'fixed';
                        stickyElem.style.top = '0rem';
                    } else {
                        stickyElem.style.position = 'absolute';
                        stickyElem.style.top =
                            window.innerWidth > 568 ? '20%' : '25%';
                    }
                };
            }
        }
    }
};
</script>

<style>
/* @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'; */
/* @import 'https://www.w3schools.com/w3css/4/w3.css'; */
</style>
<style scoped lang="scss">
.divider {
    font-family: 'Tangerine', cursive;
    font-size: 6vh;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 0.09em;
        min-width: 40vw;
    }

    &::before {
        background: linear-gradient(to right, rgba(240, 240, 240, 0), #fff);
        margin-right: 4vh;
    }

    &::after {
        background: linear-gradient(to left, rgba(240, 240, 240, 0), #fff);
        margin-left: 4vh;
    }
}
.side-page-v-header {
    height: 60vh;
    color: #fff;
}
.sticky-header-content {
    background-color: #e8e8e8;
    opacity: 0.7;
    left: 0%;
    width: 100%;
    text-align: center;
    z-index: 2;
}

.sticky-header-content h1 {
    font-family: 'Tangerine', cursive;
    font-size: 80px;
    margin: 0;
}
.desktopProfiles h2 {
    font-family: 'Tangerine', cursive;
    font-size: 50px;
}
.boxes h2 {
    font-family: 'Tangerine', cursive;
    font-size: 50px;
}
.fa {
    position: fixed;
    color: #2c3e50;
    left: 4rem;
    padding-top: 1.7rem;
    z-index: 99;
    cursor: pointer;
}

.fa-angle-left {
    left: 2.5rem;
    cursor: pointer;
}
.fa:active {
    transform: scale(0.9);
}

.fa-angle-left:active {
    transform: scale(0.9);
}
.jeremyText {
    grid-area: jt;
}
.jeremyImg {
    grid-area: ji;
}
.lyndaText {
    grid-area: lt;
}
.lyndaImg {
    grid-area: li;
}
.sText {
    grid-area: st;
}
.sImg {
    grid-area: si;
}
.desktopProfiles {
    display: grid;
    background-color: #f4f4f4;
    text-align: center;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
        '. lt li .'
        '. si st .'
        '. jt ji .';
}

.boxes {
    display: grid;
    grid-gap: 10px;
    background-color: #f4f4f4;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.box {
    background: var(--primary);
    text-align: center;
    padding: 0rem 1rem 1rem 1rem;
    box-shadow: var(--shadow);
}

.text {
    margin: 30px auto;
}
img {
    display: block;
    border-radius: 70%;
    margin: 40px auto;
    align-self: center;
    justify-content: center;
    max-height: 350px;
    min-height: 350px;
    box-shadow: 0 0 56px rgba(0, 0, 0, 0.1);
}
@media screen and (max-device-width: 480px) {
    .fa {
        left: 2rem;
        padding-top: 1.2rem;
    }

    .divider {
        &::before,
        &::after {
            content: '';
            display: block;
            height: 0.09em;
            min-width: 20vw;
        }
    }

    .text {
        margin: auto;
    }

    img {
        margin: 10px auto;
    }

    .fa-angle-left {
        left: 1rem;
    }

    .sticky-header-content h1 {
        font-size: 50px;
    }
}
</style>
