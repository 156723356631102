export const headerText = {
    title: 'North Harbor',
    aboutUs: 'About Us',
    safeAtHome: 'Safe at Home',
    shine: 'Shine',
    supportUs: 'Show Your Support',
    michener: 'North Harbor is adopting an Elementary School'
};

export const northHarbor = {
    title: 'North Harbor',
    aboutUs:
        'At North Harbor we are dedicated to assisting and advocating for you an your loved ones.',
    email: 'ContactUs@mynorthharbor.com',
    address: '6 Spring St.Suite 103 St.Ignace MI 49781',
    phone: '(517) 260-2393'
};
export const bannerText = {
    titleText: 'Inspiring a Better Michigan',
    contactUs: {
        title: 'Ask how we can help you:',
        description:
            '<b>Learn More about the work we do</b><br><br>Request more information on our Non-Profit, our mission, and the work we are doing today.<br><br><b>Iquire about Safe At Home program</b><br><br>If you or a loved one is living alone one of our Safe At Home programs can offer comfort.<br><br><b>Get involed in helping us better our communities</b><br><br>We can not do the work we do without the help of our fellow community, figure out how you can join our team.'
    },
    aboutUsText:
        'At North Harbor we are dedicated to assisting and advocating for you an your loved ones.<br><br>Our Non-profit was established based on the needs we have observed through our work in the Human Services field.<br><br>North Harbor continually strives to develop new programs to enrich our daily lives in Northern Michigan.',
    safeAtHomeOne: {
        title: 'Ring Us',
        description:
            'Clients are provided a phone number to call daily within a designated time frame to check-in.<br><br> If our client does not check in a Care Caller will reach out to the client. If contact cannot be made the Care Caller will initiate the emergency protocol.',
        prices:
            '<b>Cost for this service:</b><br> Monday – Friday $20.00<br> Monday- Sunday $40.00'
    },
    safeAtHomeTwo: {
        title: 'Care Call',
        description:
            'During each reassurance call by Care Callers can be used to give medication, meal and activity reminders or just to check-in and provide some friendly conversation.<br><br> If our client does not answer the Care Caller after three attempts in one hour the Care Caller will initiate the emergency protocol.',
        prices:
            '<b>Cost for this service:</b><br>Our Standard Plan (Calls made Monday-Friday) starts at only $80.00/month. Making our service an affordable alternative to in-home care or a supplement to other care options.'
    },
    michener: 'The vision of Michener Elementary is to provide its students with new and diverse experiences.<br> North Harbor Inc. is partnering with Michener Elementary in Adrian, MI to establish an activity fund.<br> The school has a poverty rate of 89%, and its dedicated staff spends much of their time meeting the basic needs of their students.<br> However, their vision is to provide students with new and diverse experiences.<br> By contributing to the activity fund, you can give the students opportunities they might not have access to otherwise.<br> Join us in supporting Michener Elementary and help make a difference in the lives of these students.',
    shine:
        'Shine is founded on the philosophy that a health smile is essential to a healthy happier life.<br><br> With the absorbent cost of dental coverage many hard working people find it impossible to care properly for their dental health.<br><br> Shine is present in the Mackinac, Chippewa and Cheboygan counties striving to make a difference in a person’s life.'
};


export const footerText = {
    footer: 'Our mission is simple: Do Something that Matters.'
};

export const profiles = {
    jeremy: {
        name: 'Jeremy Hepker',
        description:
            'Our IT expert, Jeremy Hepker, focuses on building and maintaining a modern technical infrastructure for which we run our business on. Raised on Mackinac Island, Jeremy was an active volunteer and member of his community. Throughout his Northern Michigan upbringing, Jeremy has identified the shortcomings of the healthcare system, and is dedicated to helping us drive our mission to the finish line.<br><br> Prior to joining the North Harbor team, Jeremy was pursuing his education in Computer Science and Physics from the University of Michigan, and now splits his time between developing secure networks for North Harbor and working in the Technology Industry. Jeremy recognizes the importance of keeping our customers information safe while developing efficient systems to help maximize our impact throughout our communities.'
    },
    lynda: {
        name: 'Lynda Hepker',
        description:
            'Our Founder and Executive Director, Lynda Hepker, through 20 years of hands-on involvement across her community recognized the vast shortcomings of Northern Michigan’s health and welfare system. Lynda founded North Harbor and established the simple premise that we live by, The Mission is simple: Do Something That Matters.<br><br> Before devoting herself to building North Harbor, Lynda worked as an In-Home Parenting Advocate for Child and Family Services of the Upper Peninsula, supervising the Child and Family Program for the Eastern Upper Peninsula. Through working across the expansive geographic area she observed countless struggles and shortcomings of the services available to these communities. Putting her Science in Human Service Management education to work Lynda has set out to be the catalyst for change in our communities.'
    },
    shari: {
        name: 'Shari Piraino',
        description:
            'Our Director of Shine, Shari Pirano, has dedicated her life to combating the barriers that the hard working people of Northern Michigan face everyday. With over 10 years in the human services field Shari has worked and lived in this community, constantly educating herself on the oral health programs available across the Easter Upper Peninsula.<br><br> Prior to joining the North Harbor family Shari utilized her Science in Sociology education from Northern Michigan University working as a Community Outreach Specialist, grant writer, financial management expert, and most recently working close to a decade as an In-Home Parenting Advocate for Child and Family Services of the Upper Peninsula. Shari has always found meaning in work dedicated to promoting everyone’s right to health and wellness.'
    }
};
