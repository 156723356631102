<template>
    <div>
        <i class="fa fa-angle-left" :class="iconSize" @click="goHome"></i>
        <i class="fa fa-home" :class="iconSize" @click="goHome"></i>
        <div
            class="sticky-header-content"
            v-bind:style="{ position: 'absolute', top: headerHight }"
            ref="sticky-header-content"
        >
            <h1>{{ headerText.safeAtHome }}</h1>
        </div>
        <div class="side-page-v-header pimg5"></div>
        <div v-if="mobile">
            <div class="side-page-Boxes">
                <section class="section section-light">
                    <h2>{{ bannerText.safeAtHomeOne.title }}</h2>
                    <p v-html="bannerText.safeAtHomeOne.description" />
                    <p v-html="bannerText.safeAtHomeOne.prices" />
                    <!-- <input type="submit" value="Ask about this service" v-on:click="ringUsForm"/> -->
                </section>
                <section v-if="!inquireRingUs">
                    <div class="pimg6"></div>
                </section>
                <!-- <section v-else>
                  <ContactForm
                  title="Ask us how our Ring Us servise could help you or a loved one."
                  nextLine="I would like to iquire about your Ring Us program"
                  ></ContactForm>
                </section> -->
            </div>
            <div class="side-page-Boxes careCall">
                <section v-if="!inquireCareCall">
                    <div class="pimg7"></div>
                </section>
                <!-- <section v-else>
                  <ContactForm
                  title="Ask us how our Care Call servise could help you or a loved one."
                  nextLine="I would like to iquire about your Care Call program"
                  ></ContactForm>
                </section> -->
                <section class="section section-light">
                    <h2>{{ bannerText.safeAtHomeTwo.title }}</h2>
                    <p v-html="bannerText.safeAtHomeTwo.description" />
                    <p v-html="bannerText.safeAtHomeTwo.prices" />
                    <!-- <input type="submit" value="Ask about this service" v-on:click="callUsForm"/> -->
                </section>
            </div>
        </div>
        <div v-else>
            <div class="side-page-Boxes">
                <section class="side-section">
                    <h2>{{ bannerText.safeAtHomeOne.title }}</h2>
                    <p v-html="bannerText.safeAtHomeOne.description" />
                    <p v-html="bannerText.safeAtHomeOne.prices" />
                    <input type="submit" value="Ask about this service" v-on:click="ringUsForm"/>
                </section>
                <section v-if="!inquireRingUs">
                    <img src="../components/assets/ladyCallingSquare.jpg" />
                </section>
                <section v-else>
                  <ContactForm
                  title="Ask us how our Ring Us servise could help you or a loved one."
                  nextLine="I would like to iquire about your Ring Us program"
                  ></ContactForm>
                </section>
            </div>
            <div class="side-page-Boxes">
                <section v-if="!inquireCareCall">
                    <img src="../components/assets/manCallingSquare.jpg" />
                </section>
                <section v-else>
                  <ContactForm
                  title="Ask us how our Care Call servise could help you or a loved one."
                  nextLine="I would like to iquire about your Care Call program"
                  ></ContactForm>
                </section>
                <section class="side-section">
                    <h2>{{ bannerText.safeAtHomeTwo.title }}</h2>
                    <p v-html="bannerText.safeAtHomeTwo.description" />
                    <p v-html="bannerText.safeAtHomeTwo.prices" />
                    <input type="submit" value="Ask about this service" v-on:click="callUsForm"/>
                </section>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { headerText, bannerText, footerText } from '../components/constants';
import ContactForm from './ContactForm';
import Footer from './Footer';
export default {
    name: 'SafeAtHome',
    components: {
        Footer,
        ContactForm
    },
    metaInfo: {
        title: 'Safe At Home'
    },
    data() {
        return {
            headerText,
            bannerText,
            footerText,
            iconSize: window.innerWidth > 568 ? 'w3-jumbo' : 'w3-xxlarge',
            headerHight: window.innerWidth > 568 ? '35%' : '45%',
            mobile: window.innerWidth > 568 ? false : true,
            inquireRingUs: false,
            inquireCareCall: false
        };
    },
    mounted() {
        window.scrollTo(0, 0);
        this.handleScroll();
    },
    created() {
        window.addEventListener('resize', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleScroll);
    },
    methods: {
        ringUsForm() {
          this.inquireCareCall = false;
          this.inquireRingUs = this.inquireRingUs ? false : true;
        },
        callUsForm() {
          this.inquireRingUs = false;
          this.inquireCareCall = this.inquireCareCall ? false : true;;
        },
        goHome() {
            this.$router.push('/');
        },
        handleScroll() {
            if (document.querySelector('.sticky-header-content')) {
                const stickyElem = document.querySelector(
                    '.sticky-header-content'
                );

                var currStickyPos =
                    stickyElem.getBoundingClientRect().top +
                    window.pageYOffset -
                    25;
                window.onscroll = function() {
                    if (window.pageYOffset >= currStickyPos) {
                        stickyElem.style.position = 'fixed';
                        stickyElem.style.top = '0rem';
                    } else {
                        stickyElem.style.position = 'absolute';
                        stickyElem.style.top =
                            window.innerWidth > 568 ? '35%' : '45%';
                    }
                };
            }
        }
    }
};
</script>

<style>
@import '../components/styles/style.css';
@import 'https://fonts.googleapis.com/css?family=Tangerine:700';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
@import 'https://www.w3schools.com/w3css/4/w3.css';
</style>
<style scoped lang="scss">
.side-page-v-header {
    height: 100vh;
    color: #fff;
}

.sticky-header-content {
    background-color: #e8e8e8;
    opacity: 0.7;
    left: 0%;
    width: 100%;
    text-align: center;
    z-index: 2;
}

.sticky-header-content h1 {
    font-family: 'Tangerine', cursive;
    font-size: 80px;
    margin: 0;
}
.fa {
    position: fixed;
    color: #2c3e50;
    left: 4rem;
    padding-top: 1.7rem;
    z-index: 99;
    cursor: pointer;
}

.fa-angle-left {
    left: 2.5rem;
    cursor: pointer;
}
.fa:active {
    transform: scale(0.9);
}

.fa-angle-left:active {
    transform: scale(0.9);
}

.fa-angle-double-down {
    bottom: 0;
    left: 45%;
    opacity: 0.7;
    padding-top: 0;
}

.side-page-Boxes {
    background-color: #f4f4f4;
    display: grid;
    grid-template-columns: 2fr 2fr;
    padding: 10px 0 10px 0;
}

.side-section {
    text-align: center;
    align-self: center;
    padding: 20px 20px;
    background-color: #f4f4f4;
    color: #666;
}

.side-section p {
    padding: 0rem 2rem 2rem 1rem;
    font-size: 18px;
}
.side-section h2 {
    font-size: 3vh;
    // padding: 0rem 1rem 1rem 1rem;
}

img {
    display: block;
    border-radius: 50%;
    margin: auto;
    align-self: center;
    max-height: 550px;
    box-shadow: 0 0 56px rgba(0, 0, 0, 0.1);
}
input[type='submit'] {
    border: none;
    background: #2c3e50;
    border-radius: 0.25em;
    padding: 12px 20px;
    color: #ffffff;
    font-weight: bold;
    // float: right;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    appearance: none;
}
.no-touch .vue-form input[type='submit']:hover {
    background: #42a2e1;
}
.vue-form input[type='submit']:focus {
    outline: none;
    background: #2b3e51;
}
.vue-form input[type='submit']:active {
    transform: scale(0.9);
}
@media screen and (max-device-width: 480px) {
    .fa {
        left: 2rem;
        padding-top: 1rem;
    }

    .fa-angle-left {
        left: 1rem;
    }

    .side-page-Boxes {
        padding: 0;
    }

    .sticky-header-content h1 {
        font-size: 50px;
    }

    .ptext {
        top: 40%;
    }
}
</style>
