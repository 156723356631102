import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import Home from '../views/MyNorthHarborHome';
import SafeAtHome from '../views/SafeAtHome';
import ContactUs from '../views/ContactUs';
import SupportUs from '../views/SupportUs';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/safeAtHome',
        name: 'safeAtHome',
        component: SafeAtHome
    },
    {
        path: '/contactUs',
        name: 'contactUs',
        component: ContactUs
    },
    {
        path: '/supportUs',
        name: 'supportUs',
        component: SupportUs
    },
    {
        path: '/facebook',
        beforeEnter() {
            window.open('https://www.facebook.com/upnorthpeople', '_blank');
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
