<template>
    <footer class="footer-distributed">
        <div class="footer-left">
            <h3>North<span>Harbor</span></h3>

            <p class="footer-links">
                <a href="/">Home</a>
                |
                <a href="#">Blog</a>
                |
                <a href="#">About</a>
                |
                <a href="/contactUs">Contact</a>
            </p>

            <p class="footer-company-name">
                © 2021 North Harbor. Inc.
            </p>
        </div>

        <div class="footer-center">
            <div>
                <i class="fa fa-map-marker"></i>
                <p>
                    <span>6 Spring St. Suite 103</span>
                    St. Ignace, MI - 49781
                </p>
            </div>

            <div>
                <i class="fa fa-phone"></i>
                <p>{{ northHarbor.phone }}</p>
            </div>
            <div>
                <i class="fa fa-envelope"></i>
                <p>
                    <a href="mailto:contactUs@mynorthharbor.com">{{
                        northHarbor.email
                    }}</a>
                </p>
            </div>
        </div>
        <div class="footer-right">
            <p class="footer-company-about">
                <span>Connect With Us</span>
            </p>
            <div class="footer-icons">
                <a href="https://www.facebook.com/upnorthpeople"
                    ><i class="fa fa-facebook"></i
                ></a>
                <a
                    href="https://shine-north-harbor.myshopify.com/?fbclid=IwAR3Ayi3v1DyFJeaKxaBbOZWiKWguF1Ski6n_z4LL6Me6MpZo5gyeMMoQwQY"
                    ><i class="fa fa-coffee"></i
                ></a>
                <!-- <a href="#"><i class="fa fa-instagram"></i></a> -->
                <!-- <a href="#"><i class="fa fa-linkedin"></i></a> -->
                <!-- <a href="#"><i class="fa fa-youtube"></i></a> -->
            </div>
            <p class="footer-company-about">
                <span>Our Donors</span>
            </p>
            <a href="https://www.unitedway.org/">
              <img src="../components/assets/UnitedWay.jpg" />
            </a>
        </div>
    </footer>
</template>

<script>
import { northHarbor } from '../components/constants';

export default {
    name: 'FooterPage',
    components: {},
    data() {
        return {
            northHarbor
        };
    },
    methods: {
        redirect() {
            this.$router.push('/');
        }
    }
};
</script>
<style scoped lang="scss">
header {
    text-align: center;
    padding-top: 100px;
    margin-bottom: 190px;
}

header h1 {
    font: normal 32px/1.5 'Open Sans', sans-serif;
    color: #3f71ae;
    padding-bottom: 16px;
}

header h2 {
    color: #f05283;
}

header span {
    color: #3f71ea;
}

@media (max-height: 800px) {
    footer {
        position: static;
    }
    header {
        padding-top: 40px;
    }
}

.footer-distributed {
    background-color: #2c292f;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 50px 50px 40px 50px;
    // margin-top: 80px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
    width: 30%;
}

.footer-distributed h3 {
    color: #ffffff;
    font: normal 36px 'Cookie', cursive;
    margin: 0;
}

/* The company logo */

.footer-distributed .footer-right img {
    width: 25%;
}

.footer-distributed h3 span {
    color: #e0ac1c;
}

/* Footer links */

.footer-distributed .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
}

.footer-distributed .footer-company-name {
    color: #8f9296;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
    width: 35%;
}

.footer-distributed .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
}

.footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
}

.footer-distributed .footer-center p a {
    color: #e0ac1c;
    text-decoration: none;
}

/* Footer Right */

.footer-distributed .footer-right {
    width: 30%;
}

.footer-distributed .footer-company-about {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-about span {
    display: block;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-distributed .footer-icons {
    margin-top: 25px;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;

    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;

    margin-right: 3px;
    margin-bottom: 5px;
}

/* Here is the code for Responsive Footer */
/* You can remove below code if you don't want Footer to be responsive */

@media (max-width: 880px) {
    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }
    .footer-distributed .footer-right img {
        width: 35%;
    }
    .footer-distributed .footer-center i {
        margin-left: 0;
    }
}
</style>
